
import { defineComponent, ref, onMounted, watch } from 'vue';
import { PlusIcon } from '@heroicons/vue/outline';
import { XCircleIcon } from '@heroicons/vue/solid';
import SystemStatusBadge from '@/components/SystemStatusBadge.vue';
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import { parseRole } from '@/components/utils';
import { getUsers } from '@/api/users.api';

export default defineComponent({
  name: 'users',
  components: {
    SystemStatusBadge,
    XCircleIcon,
    PlusIcon,
  },
  methods: {
    parseRole: parseRole,
  },
  setup() {
    const error = ref('');
    const loading = ref(false);
    const users = ref([]);
    const page = ref(1);
    const pageSize = ref(10);

    const fetchUsers = async (offset: number, limit: number) => {
      try {
        // need to know what the behaviour of getUsers is for no users
        // we hope it is an empty list of users!
        const fetchedUsers = (await getUsers(offset, limit)).data?.users;
        users.value = fetchedUsers;
      } catch (e) {
        error.value = 'Error fetching users';
      }
    };

    const changePage = (value: string) => {
      if (value === 'prev') {
        if (page.value > 1) {
          // this condition would always be true
          page.value = page.value - 1;
        }
      } else if (value === 'next') {
        if (users.value.length === pageSize.value) {
          page.value = page.value + 1;
        }
      } else {
        error.value = 'Invalid argument: `${value}` to changePage';
      }
    };

    watch(
      [page, pageSize],
      async ([newPage, newPageSize], [_prevPage, _prevPageSize]) => {
        loading.value = true;
        try {
          await fetchUsers(newPage, newPageSize);
        } finally {
          loading.value = false;
        }
      },
      {
        immediate: true,
      }
    );

    return {
      error,
      loading,
      users,
      page,
      pageSize,
      changePage,
    };
  },
});
